// src/components/AddCompanyPopup/AddCompanyPopup.jsx

import React, { useState, useEffect, useRef } from 'react';
import Classes from './AddCompanyPopup.module.css';
import { useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import { usePreventScrolling } from '../../Hooks/usePreventScrolling';
import { Checkbox } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateDeckInfo } from '../../redux-store/auth/actions';
import backend from '../../api';
import { useNavigate } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSvg = (props) => (
    <svg
        version="1.1"
        id="fi_179386"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 286.054 286.054"
        className="custom-svg"
        xmlSpace="preserve"
        {...props}
    >
        <g>
            <path
                d="M143.027,0C64.04,0,0,64.04,0,143.027c0,78.996,64.04,143.027,143.027,143.027
              c78.996,0,143.027-64.022,143.027-143.027C286.054,64.04,222.022,0,143.027,0z M143.027,259.236
              c-64.183,0-116.209-52.026-116.209-116.209S78.844,26.818,143.027,26.818s116.209,52.026,116.209,116.209
              S207.21,259.236,143.027,259.236z M143.036,62.726c-10.244,0-17.995,5.346-17.995,13.981v79.201c0,8.644,7.75,13.972,17.995,13.972
              c9.994,0,17.995-5.551,17.995-13.972V76.707C161.03,68.277,153.03,62.726,143.036,62.726z M143.036,187.723
              c-9.842,0-17.852,8.01-17.852,17.86c0,9.833,8.01,17.843,17.852,17.843s17.843-8.01,17.843-17.843
              C160.878,195.732,152.878,187.723,143.036,187.723z"
            />
        </g>
    </svg>
);

function AddCompanyManuallyPopup({ closePopup }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [companyInfoPopup, setcompanyInfoPopup] = useState(false);
    const [founders, setfounders] = useState('');
    const [companyName, setcompanyName] = useState('');
    const [industry, setindustry] = useState('');
    const [companyDesc, setcompanyDesc] = useState('');
    const [website, setwebsite] = useState('');

    const handleBgClick = () => {
        closePopup();
    };

    const handleCardClick = (event) => {
        event.stopPropagation();
    };

    const handleConfirmSubmit = async (e) => {
        e.preventDefault();

    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    usePreventScrolling();

    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [pdfSizeError, setpdfSizeError] = useState(false);
    const [uploadBrochure, setUploadBrochure] = useState("");
    const [videoLink, setVideoLink] = useState("");
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [openSubmittedPopup, setopenSubmittedPopup] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);

    const handleImageClicked = () => {
        fileInputRef.current.click();
    };

    const navigateTo = useNavigate();

    const companyId = onBoarding?._id;
    const userID = user?._id;

    const fileInputRef = useRef(null);


    const [pdfTypeError, setpdfTypeError] = useState(false);
    const [fileName, setfileName] = useState('');
    const [fileSize, setFileSize] = useState('');
    const handleFileChange = (files, name) => {
        if (files && files.length > 0) {
            const file = files[0];
            if (file.type !== 'application/pdf') {
                setpdfTypeError(true);
                return;
            }

            const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
            if (file.size > maxSize) {
                setpdfSizeError(true);
                return;
            }

            setfileName(files[0].name);
            setFileSize((files[0].size / (1024 * 1024)).toFixed(2));

            // closeUploadPopup();
            uploadMedia(files, name);
        }
    };

    const handleInputChange = (e) => {
        const files = e.target.files;
        const name = e.target.name;
        handleFileChange(files, name);
        // Removed duplicate uploadMedia call
    };

    const uploadMedia = async (files, name) => {
        const isValid = Array.from(files).every((file) => file.type.includes("application"));
        console.log(files);
        if (!isValid) {
            alert("File format not supported");
            return;
        }

        try {
            setLoadingFile(true);
            const formData = new FormData();
            Array.from(files).forEach((file) => formData.append("files", file));
            console.log(formData);
            const response = await axios.post("https://evala-aws-server-f7f37b27f032.herokuapp.com/api/deck/upload", formData);
            console.log(response);

            if (name === "uploadBrochure") {
                const updatedFiles = response.data.data.map((d) => ({ ...d, key: d.name }));
                console.log(updatedFiles);
                setUploadBrochure(updatedFiles[0]?.imgurl);
                // handleSave(updatedFiles[0]?.imgurl);
            }
        } catch (error) {
            alert(error.response?.data?.message || error.message || "An error occurred");
            setLoadingFile(false);
        } finally {
            setLoadingFile(false);
        }
    };

    const handleSave = async (e) => {
        e.preventDefault()

        const body = {
            url: uploadBrochure,
            userId: userID,
            companyId: companyId,
            videoLink: videoLink,
            adminDeck: false
        };

        console.log(body);
        try {
            const response = await backend.post(`/deck/`, body);
            console.log(response);

            if (response?.data) {
                setLoading(false);
                navigateTo("/submitted");
            }

        } catch (error) {
            console.error("Failed to post data to server", error);
            setLoading(false);
            alert("Failed to save data");
        }
    };

    return (
        <>
            <motion.div
                initial={{ opacity: 0 }} // Animation when component enters
                animate={{ opacity: 1 }}   // Animation when component is open
                exit={{ opacity: 0 }}      // Animation when component exits
                transition={{ duration: 0.2 }}
                className={Classes.popup}
                onClick={handleBgClick}
            >
                <motion.div
                    initial={{ y: 7 }} // Animation when component enters
                    animate={{ y: 0 }}   // Animation when component is open
                    exit={{ y: 7 }}      // Animation when component exits
                    transition={{ duration: 0.25 }}
                    className={Classes.card}
                    onClick={handleCardClick}
                >
                    <div className={Classes.close} onClick={closePopup}>
                        <svg height='20px' fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                        </svg>
                    </div>
                    <div className={Classes.bgPopup} style={{ background: onBoarding?.color }}>
                        <div></div>
                        <h5 className={Classes.title2}>Add Company</h5>
                        <div className={Classes.curveCon}></div>
                    </div>
                    <form onSubmit={handleConfirmSubmit}>
                        <div className={Classes.cardCon}>
                            <h5 className={Classes.title}>Add company information to upload to your screening dashboard.</h5>
                            <div className={Classes.selectionCon}>
                                <input
                                    onChange={(e) => setcompanyName(e.target.value)}
                                    type="text"
                                    placeholder="Company name"
                                    required
                                    className={`${Classes.inputField}`}
                                    value={companyName}
                                />
                                <input
                                    onChange={(e) => setfounders(e.target.value)}
                                    type="text"
                                    placeholder="Founders"
                                    required
                                    className={`${Classes.inputField}`}
                                    value={founders}
                                />
                                <input
                                    onChange={(e) => setindustry(e.target.value)}
                                    type="text"
                                    placeholder="Industry"
                                    required
                                    className={`${Classes.inputField}`}
                                    value={industry}
                                />
                                <textarea
                                    onChange={(e) => setcompanyDesc(e.target.value)}
                                    type="text"
                                    placeholder="Company description (100 words or less)"
                                    required
                                    className={`${Classes.inputField}`}
                                    value={companyDesc}
                                    rows={5}
                                    style={{
                                        height: 'auto',
                                        paddingTop: '10px',
                                        resize: 'none'
                                    }}
                                >
                                </textarea>
                                <input
                                    onChange={(e) => setwebsite(e.target.value)}
                                    type="text"
                                    placeholder="Website"
                                    required
                                    className={`${Classes.inputField}`}
                                    value={website}
                                />
                                <h5 className={Classes.uploadText} > Upload pitch deck (up to 10MB) </h5>
                                <div className={Classes.uploadCon} onClick={handleImageClicked} >
                                    <input
                                        ref={fileInputRef}
                                        onChange={handleInputChange}
                                        accept="application/pdf"
                                        name="uploadBrochure"
                                        className="form-control"
                                        type="file"
                                        id="formFile"
                                        style={{ display: 'none' }}
                                    />
                                    <img src='/icons/fileIcon.png' />
                                    {loadingFile ? <h5>Uploading...</h5> :
                                        uploadBrochure ? <h5>{fileName.slice(0, 15)}.pdf</h5> : <h5>Choose file</h5>}
                                </div>
                            </div>
                            <div className={Classes.flexAlign}>
                                <button
                                    type='submit'
                                    className={Classes.btn2}
                                    style={{
                                        background: onBoarding.color ? onBoarding.color : "black",
                                        border: `1px solid ${onBoarding.color}`
                                    }}
                                >
                                    <h5>Add</h5>
                                </button>
                            </div>
                        </div>
                    </form>
                </motion.div>
            </motion.div>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default AddCompanyManuallyPopup;
